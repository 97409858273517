import { computed, MaybeRefOrGetter, toValue } from "vue";
import { Roadtrip } from "../models/roadtrip";
import { Profile } from "../models/profile";

export function useRoadtripPolicy(profile: MaybeRefOrGetter<Profile | undefined>, roadtrips: MaybeRefOrGetter<Array<Roadtrip>>) {
  return {
    hasRoomForNewRoadtrip: computed(() => {
      if(!toValue(roadtrips).length) return true; // If they haven't got any road trips, let them create one
    
      // Note we'll override BOTH undefined AND 0 to be 1 - the side-effect is that setting the max to 
      // zero doesn't disable road trip creation
      const maxAllowedRoadtrips = toValue(profile)?.maxAllowedRoadtrips || 1;
    
      return (toValue(roadtrips).length < maxAllowedRoadtrips);
    })
  }

}

